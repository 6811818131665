/*eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <div>
          <h3 class="launchpad_text">
            <span class="primary-color">GIDE.AI</span>: Your Gateway to Scholarships and Career Success
          </h3>
          <h3 class="mt-4">
            Sign In
          </h3>
          <p>Don't have an account? <router-link :to="{ name: 'auth.register'}">
            Sign Up
          </router-link></p>
          <div>
            <div id="buttonDiv" style="width: 100%;">
            </div>
          </div>
          <ValidationObserver ref="loginForm">
            <form class="mt-4" @submit.prevent="login">
              <div class="form-group">
                <label for="validationTooltipEmail">Email address</label>
                <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                  <input
                    v-model="userEmail"
                    type="email"
                    class="form-control mb-0"
                    id="validationTooltipEmail"
                    placeholder="Email"
                    name="Email">
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group" v-show="showPasswordField">
                <label for="exampleInputPassword1">Password</label>
                <router-link :to="{ name: 'auth.forgotPassword',params:{'email': userEmail} }" class="float-right">
                  Forgot password?
                </router-link>
                <input v-model="userPassword" type="password" class="form-control mb-0" id="exampleInputPassword1" placeholder="Password">
              </div>

              <div class="d-inline-block w-100">
                <div class="custom-control custom-checkbox d-inline-block mt-2 pt-1">
                  <input type="checkbox" @click="keepMeLogin = !keepMeLogin" class="custom-control-input" id="customCheck1">
                  <label class="custom-control-label" for="customCheck1">Remember Me</label>
                </div>
                <button type="submit" class="btn btn-primary float-right">
                  Sign In
                </button>
              </div>

              <!-- Google & Apple Login Login -->
              <div id="or">
                OR
              </div>

              <div class="signGoogle">
                <div class="btn btn-google" type="button" @click="login_google">
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32px" height="32px"
                      viewBox="0 0 48 48"
                      class="abcRioButtonSvg mr-2" style="margin-top: 1px;">
                    <g>
                      <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path><path fill="none" d="M0 0h48v48H0z"></path>
                    </g>
                  </svg>
                  <span>Sign in with Google</span>
                </div>
              </div>

              <!-- https://developer.apple.com/design/human-interface-guidelines/technologies/sign-in-with-apple/ -->
              <!-- https://developer.apple.com/documentation/sign_in_with_apple/displaying_sign_in_with_apple_buttons_on_the_web -->
              <!-- Google & Apple Login Login -->
              <div class="mt-3">
                <div class="" v-if="apple_signin_usePopup">
                  <div id="appleid-signin" class="signin-button" data-color="white" data-border="true" data-width="207" data-height="49" data-type="sign in"></div>
                </div>
                <div class="ml-2" v-else>
                  <div id="appleid-signin1" class="signin-button" data-color="white" data-border="true" data-width="207" data-height="49" data-type="sign in" @click="login_apple_redirect"></div>
                </div>
              </div>

              <div v-if="auth_loader" class="text-center mt-3">
                <b-spinner variant="primary" label="Spinning"></b-spinner>
              </div>

              <div class="sign-info">
                <span class="dark-color d-inline-block line-height-2">Are you a school, university, edtech or any other organisation ? <router-link :to="{ name: 'auth.user_reg_lead_add'}">Contact Us</router-link></span>
                <span class="dark-color d-inline-block mt-4" style="line-height: 1.2;">
                  <a href="mailto:support@gide.ai">Need Help !</a><br>
                  <small>[Support timings between 11am to 5pm]</small><br>
                </span>
                <div>
                  <ul class="iq-social-media mt-2">
                    <li>
                      <a href="https://www.facebook.com/highschoolmomsgroup" target="_blank"><i class="ri-facebook-box-line"></i></a>
                    </li>
                    <li>
                      <a href="https://twitter.com/hsminace" target="_blank"><i class="ri-twitter-line"></i></a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/highschoolmoms" target="_blank"><i class="ri-instagram-line"></i></a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/highschoolmoms/" target="_blank"><i class="ri-linkedin-line"></i></a>
                    </li>
                  </ul>
                </div>
              </div>
            </form>
          </ValidationObserver>

          <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
            <div v-html="toastMsg">
            </div>
          </b-toast>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script src="https://accounts.google.com/gsi/client" async defer></script>

<script>
/*eslint-disable */
import { User } from '../FackApi/api/user.js'
import AutoLogin from '../Utils/autoLogin'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'Login',
  components: {
    ValidationProvider
  },
  data: () => ({
    apple_signin_usePopup: true,
    apple_redirectURI_IOS : location.protocol+'//'+location.hostname+(location.port ? ':'+location.port : '')+'/login',
    apple_redirectURI_OTHER : location.protocol+'//'+location.hostname+(location.port ? ':'+location.port : '')+'/apple_signin_webhook',
    //apple_redirectURI_OTHER : location.protocol+'//'+location.hostname+':3000/apple_signin_webhook', //for localtesting Create a build and start the server in https mode
    apple_login_url: "https://appleid.apple.com/auth/authorize?client_id=com.gide.io.signin&redirect_uri={apple_redirectURI_OTHER}&response_type=code%20id_token&state=apple_sign_in&scope=name%20email&response_mode=form_post&frame_id=53783245-1e71-43fc-99ce-e434c0792331&m=11&v=1.5.2",
    device: "web",
    showToast: false,
    toastMsg: null,
    toastVariant: 'default',
    toastTitle: 'User',
    userEmail: '',
    userPassword: '',
    showPasswordField: false,
    keepMeLogin: false,
    // only needed if you want to render the button with the google ui
    renderParams: {
      width: 250,
      height: 50,
      longtitle: true
    },
    auth_loader: false
  }),
  async mounted () {
    // this.initiateGoogleLoginView()
    window.addEventListener("flutterInAppWebViewPlatformReady", function (event) {
      console.log("flutterInAppWebViewPlatformReady NOW")
    })

    if(navigator.userAgent.toLowerCase().includes("iphone")
      || navigator.userAgent.toLowerCase().includes("ipod")
      || navigator.userAgent.toLowerCase().includes("ipad")
    ){
      this.apple_signin_usePopup = true
    }
    else if(navigator.userAgent.toLowerCase().includes("android")){
      this.apple_signin_usePopup = false
    }
    else {
      this.apple_signin_usePopup = false
    }

    //INIT APPLE LOGIN
    this.apple_login_url = this.apple_login_url.replace("{apple_redirectURI_OTHER}", encodeURIComponent(this.apple_redirectURI_OTHER))
    this.login_apple_init()

    //Callback Handler Called after Apple Authentication with page callback. Not using Popoup Box
    if(this.$route.query.f == "apple_signin_webhook"){
      console.info("apple_signin_webhook Check", this.$route.query)
      let code = this.$route.query.code
      let id_token = this.$route.query.id_token
      let state = this.$route.query.state
      let userInfo = this.$route.query.user ? this.$route.query.user : null

      this.update_current_url("","", true)

      if(!code || !id_token || !state){
        //If any if them are null or undefined then show error
        this.showToast = true
        this.toastVariant = "danger"
        this.toastMsg = "There was a problem while Apple sign-in. Please try again"
        return
      }

      this.auth_loader = true

      console.log("apple_signin_webhook", code, id_token, state, userInfo)
      this.apple_signin_webhook(code, id_token, state, userInfo)
    }
  },
  methods: {
    /**
     * login_google
     * Login via Google
     * https://www.npmjs.com/package/vue-google-oauth2
     */
    async login_google() {
      this.auth_loader = true;

      const googleUser = await this.$gAuth.signIn()
      this.isSignIn = this.$gAuth.isAuthorized
      let user = googleUser.getBasicProfile()
      let response = googleUser.getAuthResponse()

      if (this.isSignIn) {
        let userObj = {
          user_name: user.getName(),
          user_id: googleUser.getId(),
          user_email: user.getEmail(),
          mobile_no: user.phoneNumber,
          user_image: user.getImageUrl(),
          is_verified: false,
          email_verified:true,
          token: response.id_token
        }

        AutoLogin.autoLogin(this, userObj, 'google')
        this.auth_loader = false
      }
    },
    /**
     * login
     * This method checks which method of login is being used by the user
     */
    async login () {
      let valid = await this.$refs.loginForm.validate()
      if (!valid) {
        return
      }
      if (this.userEmail !== null && this.userEmail !== '') {
        // Password field is visible to the user
        if (this.showPasswordField) {
          // This means user exists in the database
          await this.userLoginEmail()
        }
    else {
          await this.userVerify()
        }
      }
    },
    /**
     * userLoginEmail
     */
    async userLoginEmail () {
      let userObj = {
        'user_email': this.userEmail,
        'user_password': this.userPassword,
        'keep_me_logged_in': this.keepMeLogin
      }
      await AutoLogin.autoLogin(this, userObj, 'email')
    },
    /**
     * userVerify
     */
    async userVerify () {
      let userObj = {
        'user_email': this.userEmail
      }

      let userProfileData = {
        user_email: this.userEmail
      }

      let redirect_to = ''
      this.auth_loader = true
      let userVerifyResp = await User.userVerify(this, userObj)
      this.auth_loader = false

      if (userVerifyResp && userVerifyResp.resp_code == "ERR_USER_DELETED") {
        this.showToast = true
        this.toastVariant = "warning"
        this.toastMsg = userVerifyResp.resp_msg
        return
      }
      else if (userVerifyResp && !userVerifyResp.resp_status) {
        // New User - Check if Mailbox Works or not
        if (userVerifyResp.resp_data && userVerifyResp.resp_data) {
          this.showToast = true
          this.toastVariant = "warning"
          this.toastMsg = userVerifyResp.resp_data

          setTimeout(() => {
            // If user not found than set basic profile details and redirect to registration page
            redirect_to = '/register'
            this.$router.push(redirect_to)
          }, 3000)
        }
        else {
          redirect_to = '/register'
        }
      }
      else if (userVerifyResp.resp_data.user_password) {
        this.showPasswordField = true
      }
      else if (!userVerifyResp.resp_data.user_password) {
        userProfileData = userVerifyResp.resp_data
        userProfileData.user_email = this.userEmail

        if (!userVerifyResp.resp_data.user_email_verified) {
          await this.userActivationEmail()
        }

        redirect_to = '/activate'
      }
      else if (!userVerifyResp.resp_data.user_is_profile_complete || userVerifyResp.resp_data.user_is_profile_complete !== 1){
        userProfileData = userVerifyResp.resp_data
        userProfileData.user_email = this.userEmail
        redirect_to = '/quick-profile'
      }

      await this.$store.dispatch('User/setUserProfileAction', userProfileData)
      if (redirect_to) {
        await this.$router.push(redirect_to)
      }
    },
    /**
     * login_apple_init
     * Login via apple
     * https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/configuring_your_webpage_for_sign_in_with_apple
     */
    async login_apple_init() {
      /*
       * login_apple_redirect Check is only needed for APPLE LOGIN POPUP FAILED, SO LOGIN VIA REDIRECT
       * If this.$route.query.f != "login_apple_redirect" then login via popup else redirect
       */
      console.log("APPLE-LOGIN-1 login_apple_init => ",navigator.userAgent.toLowerCase())

      //Used to display the Apple login button specific to Android. Set the IOS Button to display: none
      if(
        (navigator.userAgent.toLowerCase().indexOf("iphone") > -1
        || navigator.userAgent.toLowerCase().indexOf("ipad") > -1
        )
        && this.$route.query.f != "login_apple_redirect"
      ){
        console.log("APPLE-LOGIN-2 login_apple_init => iphone or ipad", process.env.VUE_APP_APPLE_CLIENT_ID)

        //For IOS devices enable the IOS orginal button and disbale other and apple signin with popup
        this.apple_signin_usePopup = true
      }
      else{
        console.log("APPLE-LOGIN-2 login_apple_init => other", process.env.VUE_APP_APPLE_CLIENT_ID)

        //For Other devices lik android disbale the IOS orginal button and apple signin with redirect
        this.apple_signin_usePopup = false
      }

      window.AppleID.auth.init({
        clientId : process.env.VUE_APP_APPLE_CLIENT_ID,
        scope : 'name email',
        redirectURI : this.apple_redirectURI_IOS,
        state : 'apple_sign_in',
        usePopup : this.apple_signin_usePopup //false for Android/Web/mWeb and true for IOS
      })

      //Listen for authorization success
      document.addEventListener('AppleIDSignInOnSuccess', async (data) => {
        this.auth_loader = true

        console.log("APPLE-LOGIN-3 AppleIDSignInOnSuccess =>", data)

        let idtoken = data.detail.authorization.id_token
        let code = data.detail.authorization.code
        let token_parse_data = JSON.parse(atob(idtoken.split('.')[1]))
        console.log("APPLE:token_parse_data-1", token_parse_data)

        let userObj = {
          user_name: '',
          user_id: '',
          user_email: '',
          mobile_no: '',
          image: '',
          is_verified: false,
          email_verified: true,
          code: code,
          token: idtoken,
          redirectURI : this.apple_redirectURI_IOS,
        }

        if(data.detail.user){
          let user = data.detail.user
          let name = (user.name) ? user.name : {}
          let first_name = (name.firstName) ? name.firstName : ''
          let last_name = (name.lastName) ? name.lastName : ''

          userObj.user_name = first_name +' '+ last_name
          userObj.user_email = user.email
        }
        else{
          userObj.user_email = token_parse_data.email
        }

        AutoLogin.autoLogin(this, userObj, 'apple')
      })

      //Listen for authorization failures
      document.addEventListener('AppleIDSignInOnFailure', (error) => {
        this.auth_loader = false
        console.log("APPLE-LOGIN-3 AppleIDSignInOnFailure =>", error.detail.error, error)

        let error_msg = error.detail.error
        if(error_msg == "popup_closed_by_user"){
          error_msg = "You closed the Apple signin popup"
        }
        else{
          error_msg = "Looks like your device is not linked to this Apple ID or you have not signed into your iCloud Account. Kindly sign-into you iCloud account before using the option"

          /**
           * APPLE LOGIN POPUP FAILED, SO LOGIN VIA REDIRECT
           * Enable this When the below can be opened in safari browser of IOS Device. Currently it the Apple Login
           * Box open in the App itself and after validation, continue is not posting on the redirect URL. As a result the login
           * via redirect is not working on IOS.
           */
          if(window.flutter_inappwebview){
            console.log("APPLE-LOGIN-3: On Mobile App APPLE LOGIN POPUP FAILED, SO LOGIN VIA REDIRECT: window.flutter_inappwebview", {data: {url: this.apple_login_url}});
            window.flutter_inappwebview.callHandler('launchInBrowser', {data: {url: this.apple_login_url}})
          }
        }
      })

      /*
       * APPLE LOGIN POPUP FAILED, SO LOGIN VIA REDIRECT
       */
      if(this.$route.query.f == "login_apple_redirect"){
        this.update_current_url("","", true)
        this.login_apple_redirect()
      }
    },
    /**
     * login_apple_redirect
     * Login via apple using redirect instead of popup
     *
     */
    login_apple_redirect() {
      /* If you are testing via mwebdevx then u have test on port 3000. Do the following:-
       *
       * vi server.js
       * is_HTTPs = true //Set to true. After testing set to false
       * npm run build
       * nodemon server.js
       */
      try {
        this.auth_loader = true

        const data = window.AppleID.auth.signIn({
          clientId : process.env.VUE_APP_APPLE_CLIENT_ID,
          scope : 'name email',
          redirectURI : this.apple_redirectURI_OTHER,
          state : 'apple_sign_in',
          usePopup : false //For mWeb use False for Web use true
        })

        console.log("login_apple_redirect data = ", data)

        this.auth_loader = false
      }
      catch ( error ) {
        console.error("login_apple_redirect error = ", error)
        this.auth_loader = false

        this.showToast = true
        this.toastVariant = "danger"
        this.toastMsg = "Apple signin error"
        return
      }
    },
    /**
     * apple_signin_webhook
     * We are using Apple Login without Popup. So when Login is successful, apple page, does a post on the callback/redirectURL.
     * Since Vuejs cannot handle POST request, the request is actually handled by server.js
     * The POST params are extracted from the body and converted to GET and passed on to the login page.
     * Ex:- https://mwebdevx.meshchat.co:4444/login?f=apple_signin_webhook&code=c6ea3fdeae4e64fba987aecdb44c13e90.0.nvtv.lL3T8a-myfXhCO4DBqbzeA&id_token=eyJraWQiOiI4NkQ4OEtmIiwiYWxnIjoiUlMyNTYifQ.eyJpc3MiOiJodHRwczovL2FwcGxlaWQuYXBwbGUuY29tIiwiYXVkIjoiY29tLm1lc2hjaGF0LmNvLnNpZ25pbiIsImV4cCI6MTU5MjUwOTIxOSwiaWF0IjoxNTkyNTA4NjE5LCJzdWIiOiIwMDA1MzUuNDlhODQ3MzYzYmQyNGFiYWJiNWI0MzFiYjNkMjJhYTQuMTc0NiIsImNfaGFzaCI6ImYxcVphWUJYVUpWZkx5T183clo2QWciLCJlbWFpbCI6ImR6NXZ5ZDM1Z3VAcHJpdmF0ZXJlbGF5LmFwcGxlaWQuY29tIiwiZW1haWxfdmVyaWZpZWQiOiJ0cnVlIiwiaXNfcHJpdmF0ZV9lbWFpbCI6InRydWUiLCJhdXRoX3RpbWUiOjE1OTI1MDg2MTksIm5vbmNlX3N1cHBvcnRlZCI6dHJ1ZX0.bu1VWPgetg4GmpVMo6cgkE2pUQ4gxGjYOygF7hSz-5M3dfEwBvUJKJxSrm40vSfInB6gOXCFQW_6uqR3oyFeSO4EbCl0vvULzmkWJmzMQMVV8oN7UK2TUNb5rmcnGsqpbPpEzZJNI8q-Xi5ePalkE4m84Rq1GkwQai0AJWwyUsIJpRuMWp1kwYrc1Ru2Y3xeqe2AcckLH6bacnpTEhLG_pmh4wlZYXjXEbHMguXJUALAYbFSC87sU2Zfmec
     *
     */
    apple_signin_webhook(code, token, state, user_info=null){
      let token_parse_data = JSON.parse(atob(token.split('.')[1]))
      user_info = JSON.parse(user_info)
      user_info = user_info && user_info.name ? user_info.name : null
      console.log("APPLE:token_parse_data-2", token_parse_data, user_info)

      let userObj = {
        user_name: user_info ? user_info.firstName + " " + user_info.lastName : '',
        user_id: '',
        user_email: '',
        mobile_no: '',
        image: '',
        is_verified: false,
        email_verified:true,
        token: token,
        code:code,
        redirectURI : this.apple_redirectURI_OTHER,
        state:state
      }

      userObj.user_email = token_parse_data.email

      AutoLogin.autoLogin(this, userObj, 'apple')
    },
    /**
     * userActivationEmail
     */
    async userActivationEmail () {
      await User.userActivationEmail(this, this.userEmail)
    },
    /**
    * update_current_url - Update the Visible URL on Browser
    *
    */
    update_current_url(url_key, url_val=null, remove_query_string=true){
      //Update the Visible URL on Browser
      var curr_url = new URL(location.href);

      if(remove_query_string){
        //Remove the entire query string
        curr_url =  window.location.href.split("?")[0];
      }
      else{
        //Remove the selected value from query string
        var params = new URLSearchParams(curr_url.searchParams.toString());
        params = params.toString();

        if(params.indexOf(url_key) != -1){
          //Found the Param. Now Remove it
          curr_url.searchParams.delete(url_key)
        }

        if(url_val != null){
          if(curr_url.toString().indexOf("?") != -1){
            //If Found
            curr_url = curr_url + "&" + url_key + "=" + url_val
          }
          else{
            curr_url = curr_url + "?" + url_key + "=" + url_val
          }
        }
      }

      if (history.pushState) {
        window.history.pushState({path:curr_url},'',curr_url)
      }
    },
  },
  watch:{
  }
}
</script>

<style>
  @import url("../assets/css/custom.css");
  @import url("../assets/css/PriceSlider.css");
  .launchpad_text{
    height: 0px;
    position: relative;
    top: -90px;
    line-height: 1.2;
  }
   @media screen and (min-width: 320px) and (max-width: 575px) {
    .launchpad_text{
      position: unset;
      height: auto;
      line-height: 1.2;
    }
  }
  @media screen and (min-width: 576px) and (max-width: 991px) {
    .launchpad_text{
      position: relative;
      top: auto;
      height: auto;
      line-height: 1.2;
    }
  }
  .signin-button {
    margin: auto;
    display: block;
    width: max-content;
  }
  .signin-button div:first-child{
    left: -3px;
  }
  .signGoogle {
    font-size: 15px;
    text-align: center;
  }
  .btn-google {
    /* color: var(--iq-primary); */
    color: black;
    background: #fff;
    /* border-color: var(--iq-primary); */
    border-color: unset;
    font-weight:600;
  }
  #or {
    position: relative;
    height: 50px;
    background: #fff;
    line-height: 50px;
    text-align: center;
  }
  #or:after, #or:before {
    position: absolute;
    width: 40%;
    height: 2px;
    top: 24px;
    background-color: #aaa;
    content: "";
  }
  #or:before {
    left: 0;
  }
  #or:after {
    right: 0;
  }
</style>
